import React from "react"
import { Helmet } from "react-helmet"
import {
  AttributeSection,
  CTAButton,
  GetStartedButton,
} from "../commonComponents"
import { Layout } from "../components/Layout"
import {
  IntegrationDarkSection,
  IntegrationHeroSection,
  MindMap,
  ProductEngineeringIntegration,
} from "../integrationsUtils"


export default function PagerDuty() {
  const SERVICE_NAME = "pagerduty"
  const BACKGROUND_SRC = `/img/zendesk-bg.png`
  const BACKGROUND_WEB_POSITION = "100%"
  const BACKGROUND_MOBILE_POSITION = "35% 50px"
  return (
    <Layout
      title="Streamline Your Operations By Integrating IrisAgent With Pagerduty"
      keywords="pagerduty integration, PagerDuty and Zendesk integration, PagerDuty and Salesforce integration, PagerDuty and Intercom integration, PagerDuty and Freshdesk integration"
      description="Optimize your customer support operations with seamless PagerDuty integration provided with IrisAgent. Streamline your customer ticket resolution with enhance efficiency."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/pagerduty/"
        />
      </Helmet>
      <main id="main">
        {/*  ======= Hero Section =======  */}
        <IntegrationHeroSection
          serviceName={SERVICE_NAME}
          titleIntegration={
            <>
              Connect <span className="title-fucsia-color">IT ops</span> and{" "}
              <br />
              <span className="title-fucsia-color">customer support</span>
            </>
          }
          descriptionIntegration={
            <>
              <span className="web-view-new-design-display">
                Automate bidirectional workflows to get a
                <br />
                complete view of bugs and tickets. Connect
                <br />
                PagerDuty with your customer support processes.
              </span>
              <span className="mobile-view-new-design-display">
                Automate bidirectional
                <br />
                workflows to get a
                <br />
                complete view of bugs
                <br />
                and tickets. Connect
                <br />
                PagerDuty with your
                <br />
                customer support
                <br />
                processes.
              </span>
            </>
          }
          sectionBackgroundSrc={BACKGROUND_SRC}
          sectionBackgroundWebPosition={BACKGROUND_WEB_POSITION}
          sectionBackgroundMobilePosition={BACKGROUND_MOBILE_POSITION}
        />
        {/*  End Hero  */}
        {/*  ======= Cta Section =======  */}
        <IntegrationDarkSection
          label={
            <span className="integration-dark-section-title">
              <span className="title-fucsia-color">Collaborate seamlessly</span>{" "}
              throughout your organization
            </span>
          }
          button={
            <CTAButton
              to="https://www.pagerduty.com/integrations/iris-customer-support-visibility/"
              label={`FIND US ON ${SERVICE_NAME.toUpperCase()} MARKETPLACE`}
              target="_blank"
            />
          }
          firstColClassName="col-md-8"
          secondColClassName="col-md-4"
          buttonWebFlexAlignment="end"
        />
        {/*  End Cta Section  */}

        {/*  ======= MindMap Section =======  */}
        <section
          id="intro-section"
          className="section-padding-left intro-bg-col"
          style={{ backgroundColor: "#F9F9F9" }}
        >
          <div className="row">
            <MindMap
              colorLine={"#7879F1"}
              colorBox={"#05AC380D"}
              imagen={"/img/genericCustomerGreen.png"}
              icon={"/img/pagerDutyIconBox.png"}
              iconDot={"/img/pagerDutyDot.png"}
            />
          </div>
        </section>
        {/*  End MindMap Section  */}

        {/*  ======= Product Engineering Integrations Section =======  */}

        <ProductEngineeringIntegration
          imagen={"/img/pagerdutyPEI.png"}
          icon={"/img/pagerdutyMobileLogo.png"}
        />

        {/*  End Product Engineering Integrations Section  */}

        {/* //   <!-- ======= Attribute Section ======= --> */}
        <AttributeSection />
        {/* //   <!-- ======= END -- Attribute Section ======= --> */}

        {/*  ======= Cta Section =======  */}
        <IntegrationDarkSection
          label={
            <span className="integration-dark-section-title">
              <span className="web-view-new-design-display">
                <span className="title-fucsia-color">Respond faster</span> and{" "}
                <span className="title-fucsia-color">more empathetically</span>
                <br />
                to your customers
              </span>
              <span className="mobile-view-new-design-display">
                <span className="title-fucsia-color">Respond faster</span> and{" "}
                <span className="title-fucsia-color">
                  more
                  <br />
                  empathetically{" "}
                </span>
                to your customers
              </span>
            </span>
          }
          button={<GetStartedButton />}
          firstColClassName="col-md-10"
          secondColClassName="col-md-2"
        />
        {/*  End Cta Section  */}

        {/* <!-- ======= Cta Section ======= --> */}
        {/* <ContactSection></ContactSection> */}
        {/* <!-- End Cta Section --> */}
      </main>
      {/*  End #main  */}
    </Layout>
  )
}
